body {
  margin: 0;
  font-family: 'Breeze Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    background-color: #21262d;
    color: #c6cdd5;
    background-image: url("./assets/img/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
}

svg {
  color: #c6cdd5;
}

canvas{
  margin: 0 auto;
  }

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #161b22;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 #161b22,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 #161b22,
      .5em 0 0 #161b22;}}


div {
  color: #c6cdd5;
}

h1 {
  font-size: 22px;
  font-weight: 500;
  color: #c6cdd5;
  text-align: left;
  margin: 20px 0px 0px 0px;
}
h2 {
  padding-top: 8px;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  margin: 0px 0px 0px 0px;
}

code {
  font-family: 'Chillax', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Chillax';
  src: url('./fonts/Chillax-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Chillax';
  src: url('./fonts/Chillax-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Chillax';
  src: url('./fonts/Chillax-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Chillax';
  src: url('./fonts/Chillax-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}